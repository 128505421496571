import { tagCode } from "components/DesignSystemPage/TagComponent/PreviewTagComponent/tagCode";
import {
  prefixSuffixIcon,
  validationIcon,
  prefixCode,
  suffixCode,
  trimmedCode,
} from "helpers/code";

export const inputCode = (data, fluid = false, dense = false) => {
  return trimmedCode(`<div class="dss-input-wrapper dss-input dss-input-wrapper dss-input  ${dense ? data.className : " "} ${fluid ? "dss-input-fluid " : " "}  ${data.disabled ? "disabled " : " "} ${data.readOnlyState ? "read-only " : " "} ">
    <div class="dss-input-label-wrapper">
        <label for="input" class="dss-input-label">Label</label>
        <div class="dss-input-container blur">
            <div class="dss-input-leading">
            ${data.prefix ? ` ${prefixCode}` : ""}
           ${
             data.leadingIcon
               ? `<span class="dss-input-icon">
            ${prefixSuffixIcon}</span>`
               : ""
           }
             ${
               data.tag
                 ? tagCode(
                     {
                       prefixIcon: true,
                       statusIcon: false,
                       mode: "closable",
                       varient: "labelCloseicon",
                       disable: false,
                     },
                     "",
                     "small"
                   )
                 : ""
             }
                       
                <input id="input" type="text" placeholder="value" class="dss-input-field" value="" />
            </div>
            <div class="dss-input-trailing d-flex ml-auto">
               ${data.suffix ? ` ${suffixCode}` : ""}
             ${
               data.tailingIcon
                 ? `<span class="dss-input-icon">${prefixSuffixIcon}</span>`
                 : ""
             }
            </div>
        </div>
    </div>
    <div class="dss-error-container null">
        <span class="dss-error-icon">${validationIcon}
        </span>
        <h6 class="dss-error-message">Helpful validation message</h6>
    </div>
</div>`);
};
