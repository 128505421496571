import { trimmedCode } from "helpers/code";

export const tooltipCode = (data, wrap = false) => {
	return trimmedCode(`<div class="tooltip-container dss-font-color-primary dss-ui-text-md tooltip tooltip-${data.position} tooltip-${data.arrow} tooltip-with-label ${data.size ? "tooltip-large" : ""} ${wrap ? "tooltip-wrap" : ""}">
  Hover this
    <div class="tooltip tooltip-${data.position} tooltip-${data.arrow}">
      <h6 class="tooltip-label">Label</h6>
      <h4 class="tooltip-text">This is tooltip</h4>
    </div>
  </div>`);
};
