import { trimmedCode } from "helpers/code";
export const buttonCode = (data) => {
  return trimmedCode(`<button class="dss-btn dss-btn-${data?.variant} ${data?.size ? `dss-btn-${data?.size}` : ""} ${data?.fluid ? `dss-btn-fluid` : ""} ${data?.icon ? `dss-btn-icon` : ""} ${data?.disabled ? "disabled" : ""} ${data?.iconOnly ? `icon-only` : ""}" type="button">
     ${
       data.prefixIcon
         ? `<span class="dss-icon prefix-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF"><rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke="#FFFFFF" stroke-width="1.5"></rect><path d="M5.5 5.5L18.3615 18.3615" stroke="#FFFFFF" stroke-width="1.5"></path><path d="M18.3563 5.5L5.5 18.3563" stroke="#FFFFFF" stroke-width="1.5"></path></svg>
          </span>`
         : ""
     }
  Button text
   ${
     data.suffixIcon
       ? `<span className="dss-icon suffix-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF"><rect x="4.75" y="4.75" width="14.3581" height="14.3581" stroke="#FFFFFF" stroke-width="1.5"></rect><path d="M5.5 5.5L18.3615 18.3615" stroke="#FFFFFF" stroke-width="1.5"></path><path d="M18.3563 5.5L5.5 18.3563" stroke="#FFFFFF" stroke-width="1.5"></path></svg>
          </span>`
       : ""
   }
  </button>`);
};
