import React, { useState } from "react";
import "./Tooltip.scss";

const Tooltip = ({ text = "This is tooltip", children, label, wrap, size, arrow, position, className }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const handleMouseEnter = () => {
		setShowTooltip(true);
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	return (
		<div
			className={`tooltip-container dss-ui-text-md dss-font-color-primary ${className}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
			{showTooltip && (
				<div
					className={`tooltip ${position ? "tooltip-" + position : ""} ${arrow ? "tooltip-" + arrow : ""} ${size ? "tooltip-" + size : ""} ${label ? "tooltip-with-label" : ""} ${wrap ? "tooltip-wrap" : ""}`}
				>
					<h6 className="tooltip-label">{label}</h6>
					<h4 className="tooltip-text">{text}</h4>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
