import React, { useState } from "react";

import { useGlobalContext } from "context/GlobalContext";

import { AvatarDatas } from "assets/data/data";

import TabNavigation from "components/DesignSystemPage/TabNavigation";
import ComponentCard from "components/Common/ComponentCard";
import AvatarComponent from "components/Common/AvatarComponent";
import CommonModal from "components/Common/CommonModal";
import { EditIcon } from "components/Icons";

import "./PreviewAvatarComponent.scss";
import {
  avatarCode,
  avatarCodeSilhouette,
  avatarCodeTextOnly,
  avatarCodeWithSize,
} from "./avatarCode";

const PreviewAvatarComponent = ({
  tabData,
  handleTabClick,
  activeTab,
  setSelectedTab,
}) => {
  const avatarComp = [
    { title: "Double Extra Large", size: "xxl" },
    { title: "Extra large", size: "xl" },
    { title: "Large", size: "lg" },
    { title: "Normal" },
    { title: "Dense", size: "sm" },
    { title: "Denser", size: "xs" },
  ];

  const { designSystem } = useGlobalContext();

  const componentName = "avatar";
  const componentKey = `Component/${componentName}`;
  const componentData = designSystem[componentKey];

  const getPropertyData = (property, childProperty) => {
    const data =
      property && property === "color"
        ? componentData[property][childProperty][componentName]
        : componentData[property][componentName];
    return data;
  };

  const [isModalVisible, setModalVisibility] = useState(false);
  const [avatarState, setAvatarState] = useState("Default");

  const showModal = () => {
    setModalVisibility(true);
  };

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container">
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Default
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data, index) => (
          <div key={index} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              edit={true}
              showCode={true}
              htmlContent={avatarCode(data, "default")}
            >
              <AvatarComponent size="xl" state={data.state} varient="default" />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Silhouette
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data, index) => (
          <div key={index} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              edit={true}
              showCode={true}
              silhouette
              htmlContent={avatarCodeSilhouette(data)}
            >
              <AvatarComponent
                size="xl"
                state={data.state}
                content="icon"
                varient="silhouette"
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Text
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data, index) => (
          <div key={index} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              edit={true}
              showCode={true}
              htmlContent={avatarCodeTextOnly(data)}
            >
              <AvatarComponent
                size="xl"
                content="text"
                text="AA"
                state={data.state}
                varient="textonly"
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Sizes
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {avatarComp.map((data, index) => (
          <div key={index} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard
              editClick={showModal}
              edit={true}
              showCode={true}
              htmlContent={avatarCodeWithSize(data)}
            >
              <React.Fragment>
                <AvatarComponent
                  size={data.size}
                  state={data.state}
                  varient="default"
                />
                <AvatarComponent
                  size={data.size}
                  state={data.state}
                  content="icon"
                  varient="silhouette"
                />
                <AvatarComponent
                  size={data.size}
                  content="text"
                  text="AA"
                  state={data.state}
                  varient="textonly"
                />
              </React.Fragment>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>

      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-preview plt-heading-xl dis-mb-32">
            <AvatarComponent size="xl" state={avatarState} />
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Size
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                {getPropertyData("sizing")?.default?.normal?.tokenName}
              </p>
              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewAvatarComponent;
